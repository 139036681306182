// Route constants for the application
export default {
  INIT: '/init', // Path for the welcome route
  ONBOARDING: '/onboarding', // Path for the onboarding route
  LOGIN: 'auth/login', // Path for the login route
  REGISTER: 'auth/register', // Path for the registration route
  CONFIRM_EMAIL: 'auth/confirm-email', // Path for the email confirmation route
  EMAIL_CONFIRMED: 'auth/email-confirmed', // Path for the email confirmation route
  CULTIVATIONS: 'garden/cultivation',
  HOME: '/home',
  AGENDA: '/agenda',
  AGENDA_TASKS: '/agenda/tasks',
  AGENDA_ARCHIVES: '/agenda/archives',
  GARDEN: '/garden',
  GARDEN_LIST: '/garden/list',
  GARDEN_SEEDS: '/garden/seeds',
  GARDEN_ADDPLANT: '/garden/add-plant',
  GARDEN_GUIDE: '/garden/guide',
  PROFILE: '/profile',
  CALENDAR: '/calendar',
  CALENDAR_SEEDLING: '/calendar/seedling',
  CALENDAR_HARVEST: '/calendar/harvest',
};
